.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--primary-text-color);
}



body, html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.App-CourseList {
    background-color: #ab1b1b ;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white
}

.App-link {
    color: black;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.App-buttonOne {
    background-color: red;
    border: red solid 1px;
    color : white;
}

.paddingjudul {
    text-align: center;
    padding-top: 30px;
    color: white;
}

.paddingbutton {
    position: relative;
    padding-top: 20px;
}

.btn-danger {
    background-color: #ab1b1b !important;
}

.Background-Text {
    width: 80%;
    /* min-height: calc(100vh - 20px); */
    position: relative;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: var(--background-color);
    color: var(--primary-text-color);
    border-radius: 25px;
    transition: background-color 250ms ease-in-out;
}

.Text-Area {
    width: 100%;
    height: auto;
}

.TextPadding {
    padding: 20px;
    text-align: justify;
}

.bigTextPadding {
    padding: 20px;
    text-align: left;
}

.BlueCircle {
    text-align: center;
    font-size: 19px;
    height: 30px;
    width: 30px;
    background-color: #0691c4;
    border-radius: 50%;
    display: inline-block;
}

.PinkCircle {
    text-align: center;
    font-size: 19px;
    height: 30px;
    width: 30px;
    background-color: #eb44b3;
    border-radius: 50%;
    display: inline-block;
}

.bracket {
    border-top:20px;
    border-bottom:20px;
    border-right:none;
    border-left: 1px solid black;
}

.HomePageNav {
    background-color: var(--background-color);
    height: 30px;
    width: 100%;
}