
* {
    font-family: "Verdana", cursive, sans-serif;
    color: var(--primary-text-color);
}

.Quizbody {
    background-color: #ab1b1b;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.quiz {
    background-color: var(--background-color);
    width: 450px;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-evenly;
}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;
}

.question-count span {
    font-size: 28px;
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

button {
    width: 100%;
    font-size: 16px;
    color: var(--primary-text-color);
    background-color: var(--background-color);
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid var(--primary-text-color);
    cursor: pointer;
    transition: background-color 250ms ease-in-out;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}

button:hover {
    background-color: #555e7d;
}

button:focus {
    outline: none;
}

button svg {
    margin-right: 5px;
}

.soal {
    border-radius: 5%;
}